import styled from "styled-components";

const StyledWhy = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 72px;
    .logo-prod{
        width: 300px;
        height: auto;
    }

    .separator{
        width: 900px;
        height: 0.5px;
        margin: 72px 0px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 40px;
    }

    .container-text-why{
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 60px 0px;
        margin: 36px 0px 84px 0px;
        p{
            width: 900px;
            font-family: Thasadith;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            text-align: justify;
            color: #FFFFFF;
    
            span{
                font-family: Thasadith;
                font-style: normal;
                font-weight: normal;
                font-size: 24px;
                text-align: justify;
                color: rgba(231, 161, 58, 1);
            }

            small{
                font-family: Thasadith;
                font-style: italic;
                font-weight: normal;
                font-size: 24px;
                text-align: justify;
                color: rgba(255, 255, 255, 0.6);
            }
        }
    }

    @media (max-width: 1000px){
        .separator{
            width: 80%;
        }
        .container-text-why{
            p{
                width: 80%;
            }
        }
    }

    @media (max-width: 800px){
        .container-text-why{
            p{
                font-size: 18px;
                span{
                    font-size: 18px;
                }
                small{
                    font-size: 18px;
                }
            }
        }
        .logo-prod{
            width: 200px;
            height: auto;
        }
    }
`

export default StyledWhy