import styled from "styled-components";

const StyledImagePour = styled.div`
    width: 100%;
    margin: 84px 0px;
    img{
        width: 50%;
        height: auto;
    }
    .container-text{
        position: relative;
        width: 50%;
        background: rgba(0, 0, 0, 0.3);
        padding: 60px 0px;
        display: flex;
        align-items: center;
        justify-content: center;

        p{
            width: 90%;
            font-family: Thasadith;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
            text-align: justify;
            color: #FFFFFF;

        }

        i{
            color: rgba(255, 255, 255, 0.5);
            font-style: italic;
        }

        span{
            position: absolute;
            bottom: 12px;
            right: 12px;
            font-family: Thasadith;
    
            font-weight: normal;
            font-size: 16px;
            text-align: justify;
            color: rgba(255, 255, 255, 0.6);
        }
    }

    .container-director-text{
        display: flex;
        align-items: center;
    }
    .reverse{
        flex-direction: row-reverse;
    }

    @media (max-width: 1600px){
        .container-director-text{
            display: flex;
            flex-direction: column;
        }
    }

    @media (max-width: 800px){
        img{
            width: 100%;
        }
        .container-text{
            width: 100%;

            p{

                font-size: 18px;
            }
        }
    }
`

export default StyledImagePour