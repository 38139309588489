import icon1 from '../Ressources/tableRonde.svg'
import img1 from '../Ressources/tableRonde.jpg'
import icon2 from '../Ressources/interviews.svg'
import img2 from '../Ressources/interviews.jpg'
import icon3 from '../Ressources/podcast.svg'
import img3 from '../Ressources/podcast.jpg'
import icon4 from '../Ressources/seqVideo.svg'
import img4 from '../Ressources/seqVideo.jpg'

export default[
    {
        icon: icon1,
        title: 'TABLES RONDES',
        para: 'Avec ce genre de séquence, l’objectif est d’avoir des débats constructifs autour des conditions et méthodes d’enseignement que l’on propose en France. L’idée est de faire plusieurs tables rondes avec des profils différents pour avoir une bonne mixité dans les échanges. Les contradictions montreront à quel point chacun ne vit pas les choses de la même manière. Ici, le but n’est pas de donner raison à quelqu’un mais que chacun exprime son ressenti et son point de vue librement.  La plupart des conflits dans l’enseignement viennent d’un manque de communication, cette table ronde vise à déconstruire les a priori sur la question.',
        img: img1
    },
    {
        icon: icon2,
        title: 'INTERVIEWS',
        para: "Quoi de mieux pour parler d’un sujet que de laisser les gens concernés s’exprimer?\n Je souhaite mettre la parole des élèves au premier plan pour qu’ils puissent dire tout ce qu’ils ont sur le cœur et ce dont ils ont besoin. En variant les profils interviewés, le documentaire proposera une exploration quasi complète de ce qu’est l’enseignement aujourd’hui et des pistes à explorer pour le faire évoluer. ",
        img: img2
    },
    {
        icon: icon3,
        title: 'PODCAST ILLUSTRÉS',
        para: 'Comme tout le monde n’est pas à l’aise avec la caméra, je trouve que les podcasts et messages vocaux sont des bons moyens de mettre à l’aise  et libérer la parole. Depuis le confinement, les podcasts ont gagné en popularité et voient la courbe d’audience monter.\n  Des animations 2D, du motion design et/ou des archives accompagneront et illustrerons le son. Ce genre de séquence peut fortement dynamiser le montage et stimuler l’œil du spectateur.',
        img: img3
    },
    {
        icon: icon4,
        title: 'SÉQUENCES VIDÉO',
        para: 'Comme rien n’est plus parlant que le réel, je profiterais de ma présence dans les écoles, ateliers ou autres établissement pour filmer. Avoir en images des émotions sincères ou des comportements naturels donnera de la profondeur aux propos des interviews. De plus, l’immersion sur le terrain est la partie qui m’est la plus chère.',
        img: img4
    }
]