import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import { BurgerMenuContext } from '../../Context/BurgerMenuContext'
import StyledModalLanding from './StyledModalLanding'
import { useLocation } from 'react-router-dom';
import { IoClose } from 'react-icons/io5'
export const ModalLanding = () => {

    const {toggleModal} = useContext(BurgerMenuContext)
    const location = useLocation()
    const newlocation = location.pathname

    return (
        <StyledModalLanding newlocation={newlocation} onClick={toggleModal}>
            { location.pathname === '/' &&
                <div className='container-title-modal'>
                    <h1>APPRENDRE</h1>
                    <span>Série Documentaire</span>
                    <small>Dépôt N°A21 09 1069 00</small>
                </div>
            }
            <nav >
                <ul>
                    <Link to='/note'>note d’intention<div className='blue'></div></Link>
                    <Link to='/sequences'>types de séquences<div className='red'></div></Link>
                    <Link to='/chapitres'>LES CHAPITRES<div className='blue'></div></Link>
                </ul>
                <ul>
                    <Link to='/itw1'>interviews<div className='red'></div></Link>
                    <Link to='/pourquoi'>POURQUOI 416 PROD<div className='yellow'></div></Link>
                    <Link to='/bio'>À PROPOS DE YAMI<div className='yellow'></div></Link>
                </ul>
            </nav>
            { location.pathname === '/' &&
                <div className='container-footer'>
                    <h2>Si on doit parler d’enseignement, faisons-le avec l’apprenant</h2>
                </div>
            }
            <IoClose/>
        </StyledModalLanding>
    )
}
