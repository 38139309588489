import React, {useState, useContext} from 'react'
import StyledLanding from './StyledLanding'
import { HiOutlineArrowRight } from 'react-icons/hi';
import { ModalLanding } from '../ModalLanding/ModalLanding';
import { BurgerMenuContext } from '../../Context/BurgerMenuContext';
import { ModalWelcome } from '../ModalWelcome/ModalWelcome';

export const Landing = () => {
    const [dateInput, setDataInput] = useState('')
    const [isOpenWelcome, setIsOpenWelcome] = useState(false)

    const { isOpen, toggleModal } = useContext(BurgerMenuContext)

    const handleInput = (e) => {
        setDataInput(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(dateInput === '416prod'){
            setIsOpenWelcome(!isOpenWelcome)
        }
        setDataInput('')
    }

    return (
        <StyledLanding isOpenWelcome={isOpenWelcome}>
            <div className='border'>
                <h1>APPRENDRE <span>Série Documentaire</span></h1>
            </div>
            <div className='background-girl'>
                <form onSubmit={e => handleSubmit(e)}>
                    <div className='deco-bar'></div>
                    <img src='../../images/logoProd.png' alt='logo 416 prod'/>
                    <p>À l’intention de Mélissa Theuriau</p>
                    <label>Mot de passe</label>
                    <div className='input-form'>
                        <input onInput={handleInput} type='password' value={dateInput} required/>
                        <button type='submit'><HiOutlineArrowRight/></button>
                    </div>
                </form>
            </div>
            <div className='border'>
                <h2>écrit par <span>Yami QUINTON</span></h2>
            </div>
            { isOpenWelcome &&
                <ModalWelcome 
                    toggleModal={toggleModal} 
                    setIsOpenWelcome={setIsOpenWelcome} 
                />
            }
            {  isOpen  && 
                <ModalLanding/>
            }
        </StyledLanding>
    )
}
