import styled from "styled-components";

const StyledModalVideo = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(196, 196, 196, 0.02);
    backdrop-filter: blur(100px);
    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        position: absolute;
        top: 42px;
        left: 30px;
        width: 36px;
        height: 36px;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        transition: 300ms;
    }
    svg:hover{
        color: #FFFFFF;
    }

    .wrapper-video{
        position: relative;
        width: 90%;
        height: calc(95vw * 0.5628205128205128);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        z-index: 2;

        img{
            width: 100%;
        }

        .wistia_responsive_wrapper {
            height: 100%;
            width: 100%;
            position: unset!important;
        }

        .wistia_responsive_wrapper iframe {
            height: 100% !important;
            width: 100% !important;
        }
    }
`

export default StyledModalVideo