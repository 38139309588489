import styled from "styled-components";

const StyledPresPerson = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 48px 0px;
    .container-text{
        height: 100px;
        padding: 12px 48px;
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        h3{
            font-family: Thasadith;
            font-style: italic;
            font-weight: normal;
            font-size: 24px;
            text-align: justify;
            color: #FFFFFF;
        }
        p{
            width: 700px;
            font-family: Thasadith;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            text-align: justify;
            color: #FFFFFF;
        }
    }
    .container-img{
        width: 300px;
        height: 300px;
        border-radius: 60px;
        overflow: hidden;
        img{
            width: 100%;
            height: 100%;
        }
    }

    .yellow{
        background: #F0A83B;
    }

    .red{
        background: #8F041D;
    }

    .blue{
        background: #2B9299;
    }

    @media (max-width: 1200px){
        .container-text{
            width: 50%;
            p{
                width: 90%;
            }
        }
        .container-img{
            width: 30%;
            height: auto;
        }
    }

    @media (max-width: 800px){
        overflow: hidden;
        flex-direction: column-reverse;
        .container-text{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h3{
                margin-bottom: 12px;
            }
            p{
                width: 90%;
            }
        }
        .container-img{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40%;
            height: auto;
            margin-bottom: 24px;
            border-radius: 20%;
        }
    }

    @media (max-width: 500px){
        .container-text{
            height: 150px;
        }
    }
`

export default StyledPresPerson