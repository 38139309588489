import React from 'react'
import ArrayItw from '../../Data/ArrayItw'
import { ImageDesc } from '../ImageDesc/ImageDesc'
import { PresPerson } from '../PresPerson/PresPerson'
import StyledItw2 from './StyledItw2'
import { useContext } from 'react'
import { BurgerMenuContext } from '../../Context/BurgerMenuContext'
import { ModalLanding } from '../ModalLanding/ModalLanding'

export const Itw2 = () => {

    const {isOpen} = useContext(BurgerMenuContext)

    return (
        <StyledItw2>
            <ImageDesc param={ArrayItw.image7}/>
            <PresPerson param={ArrayItw.pres4}/>
            <PresPerson param={ArrayItw.pres5}/>
            <PresPerson param={ArrayItw.pres6}/>
            <ImageDesc param={ArrayItw.image8}/>
            <PresPerson param={ArrayItw.pres7}/>
            <ImageDesc param={ArrayItw.image9}/>
            <ImageDesc param={ArrayItw.image10}/>
            <PresPerson param={ArrayItw.pres8}/>
            <PresPerson param={ArrayItw.pres9}/>
            <ImageDesc param={ArrayItw.image11}/>
            { isOpen && 
                <ModalLanding/>
            }
        </StyledItw2>
    )
}
