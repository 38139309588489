import styled from "styled-components";

const StyledModalWelcome = styled.div`

@keyframes openNav{
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes closeNav{
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

position: absolute;
width: 100%;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;

.wrapper-modal{
    width: 100%;
    height: calc(100% - 300px);
    min-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(25, 107, 113, 0.6);
    border: 3px solid #FFFFFF;
    backdrop-filter: blur(100px);
    animation: 500ms ${({isOpenWelcome}) => isOpenWelcome ? 'closeNav' : 'openNav'};
    opacity: ${({isOpenWelcome}) => isOpenWelcome ? '0' : '1'};
    h1{
        font-family: Amatic SC;
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        color: #FFFFFF;
    }
    .separator{
        width: 200px;
        height: 3px;
        background: #F9AE3D;
        border-radius: 6px;
        margin: 24px 0px;
    }

    p{
        width: 550px;
        font-family: Thasadith;
        font-style: italic;
        font-weight: normal;
        font-size: 24px;
        line-height: 100%;
        text-align: justify;
        color: rgba(255, 255, 255, 0.5);
        span{
            font-family: Thasadith;
            font-style: italic;
            font-weight: bold;
            font-size: 24px;
            line-height: 100%;
            text-align: justify;
            color: #F9AE3D;
        }
    }

    .wrapper-button{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 36px;

        h3{
            font-family: Amatic SC;
            font-style: normal;
            font-weight: bold;
            font-size: 47px;
            line-height: 100%;
            text-align: center;
            color: #FFFFFF;
        }
    
        button{
            width: 36px;
            height: 36px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border-radius: 100%;
            border: 1px solid white;
            margin-top: 6px;
            margin-left: 12px;
            cursor: pointer;
            svg{
                color: white;
            }
        }
    }
}

@media (max-width: 800px){
    .wrapper-modal{
        height: 100%;
        h1{
            font-size: 24px;
            width: 90%;
        }
        p{
            width: 90%;
            font-size: 16px;
            span{
                font-size: 16px;
            }
        }
    }
}
`

export default StyledModalWelcome