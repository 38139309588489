import img1 from '../Ressources/liberty.jpg'
import img2 from '../Ressources/action-enfance.jpg'
import img3 from '../Ressources/bras-anime.jpg'
import img4 from '../Ressources/masterclass.jpg'
import img5 from '../Ressources/au-tableau.jpg'

export default[
    {
        img: img1,
        classname: 'container-director-text',
        para: <p>“Liberté, Égalité, Improvisez !”. Même après avoir regardé les autres, je me dit encore que c’est celui qui m’a le plus parlé.  Il n’y a pas une seule minute qui ne m’a pas parlé ou qui ne m’a pas fait penser à mon projet. Le fait que le Président de la République se soit intéréssé à la place de l’improvisation dans les écoles montre que ce genre d’initiative peut changer nos écoles. C’est quelque chose de très motivant à mes yeux.</p>,
        desc: ''
    },
    {
        img: img2,
        classname: 'container-director-text reverse',
        para: <p>En voyant le documentaire, j’ai aussi énormément pensé à un de mes courts métrages, réalisé en partenariat avec Action Enfance. <i>On Improvise (4”)</i> dans lequel un enseignant permet à ses élèves de laisser libre cours à leur imagination avec l’improvisation. (<i>Lien en bas de page</i>). Il y a eu 150 scénarios proposés pour 15 sélectionnés. La première diffusion des courts métrages s’est faite au Grand Rex.</p>,
        desc: <i>On improvise - 2018</i>
    },
    {
        img: img3,
        classname: 'container-director-text',
        para: <p>Rencontre avec mon agresseur, L’absente, maman est en prison... Chaque documentaires que j’ai pu trouver m’a parlé d’une façon ou d’une autre.<br/> Dans Bouche Cousue, j’ai également beaucoup apprécié les petites animations <i>(Cf capture d’écran).</i></p>,
        desc: ''
    },
    {
        img: img4,
        classname: 'container-director-text reverse',
        para: <p>En regardant “Au Tableau”, j’ai tout de suite pensé aux masterclass de  l’Atelier 7. Avoir ces professionnels du cinéma à deux mètres de nous, attentifs aux regards, aux questions... Nous étions comme des enfants l’espace de quelques heures. Les yeux pétillants de curiosité face à tout ces témoignages et explications concrètes.</p>,
        desc: <i>Masterclass de Pascale Marin à l’Atelier 7 - 2021</i>
    },
    {
        img: img5,
        classname: 'container-director-text',
        para: <p>À mon sens, cette émission ne devrait pas juste être une émission, mais quelque chose de réel et bien installé dans notre système scolaire. On manque souvent d’exemples concrets de métier à l’école. Avoir de temps en temps des interventions de professionnels de secteurs différents donnerait une autre vision de l’enseignement aux élèves. (ex : artisans, sportifs, physiciens, libraires, historiens, avocats...)</p>,
        desc: ''
    }
]