import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import BurgerMenuContextProvider from './Context/BurgerMenuContext';
import { Home } from './pages/Home';
import { Statement } from './pages/Statement';
import './style/global.css'
import { Chapitre } from './pages/Chapitre';
import { Sequences } from './pages/Sequences';
import { Interviews1 } from './pages/Interviews1';
import { Interviews2 } from './pages/Interviews2';
import { Pourquoi } from './pages/Pourquoi';
import { Biographie } from './pages/Biographie';

  const Index = () => {
    return(
      <Router>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/note" element={<Statement/>}/>
          <Route path="/chapitres" element={<Chapitre/>}/>
          <Route path="/sequences" element={<Sequences/>}/>
          <Route path="/itw1" element={<Interviews1/>}/>
          <Route path="/itw2" element={<Interviews2/>}/>
          <Route path="/pourquoi" element={<Pourquoi/>}/>
          <Route path="/bio" element={<Biographie/>}/>
          <Route path="*" element={<Navigate to ="/" />}/>
        </Routes>
      </Router>
    )
  }

ReactDOM.render(
  <BurgerMenuContextProvider>
    <Index/>
  </BurgerMenuContextProvider>,
  document.getElementById('root')
);
