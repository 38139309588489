import React, {createContext, useState} from 'react'

export const BurgerMenuContext = createContext()

const BurgerMenuContextProvider = props => {
    const [isOpen, setIsOpen] = useState(false)

    const toggleModal = () => {
        setIsOpen(!isOpen)
    }

    return(
        <BurgerMenuContext.Provider value={{toggleModal, isOpen}}>
            {props.children}
        </BurgerMenuContext.Provider>
    )
}

export default BurgerMenuContextProvider