import React from 'react'
import StyledPresPerson from './StyledPresPerson'
import wave from '../../Ressources/wave.png'

export const PresPerson = ({ param }) => {
    return (
        <StyledPresPerson>
            <div className={`container-text ${param.classname}`}>
                <h3>{param.title}</h3>
                <p>{param.para}</p>
            </div>
            <div className='container-img'>
                <img src={param.img} alt='picture person'/>
            </div>
        </StyledPresPerson>
    )
}
