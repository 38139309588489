import React, { useState } from 'react'
import StyledModalFooter from './StyledModalFooter'
import iconVideo from '../../Ressources/video.svg'
import Improvise from '../../Ressources/Improvise.png'
import clac from '../../Ressources/clac.png'
import Boutique from '../../Ressources/Boutique.jpg'
import Chasseur from '../../Ressources/Chasseur.png'

export const ModalFooter = ({isOpen, setIsOpen, setNumber, number}) => {
    const toogleModal = () => {
        setIsOpen(!isOpen)
    }

    const toogleSelect = (param) => {
        setNumber(param)
        setIsOpen(!isOpen)
    }

    return (
        <StyledModalFooter number={number} isOpen={isOpen}>
            <div className='container-video'>
                <div onClick={toogleModal} className='first-place'>
                    <div className='container-icone'>
                        <img src={iconVideo} alt='icon video'/>
                    </div>
                    <div className='separator'></div>
                    <small className='clic'>Cliquez pour afficher les vidéos</small>
                </div>
                <div className='container-img'>
                    <div onClick={() => toogleSelect('one')} className='image-text'>
                        <div className='image-croper'>
                            <img src={Improvise} alt='miniature'/>
                        </div>
                        <span>On improvise <small>2019</small></span>
                    </div>
                    <div onClick={() => toogleSelect('two')} className='image-text'>
                        <div className='image-croper'>
                            <img src={Chasseur} alt='miniature'/>
                        </div>
                        <span>Paloma et les chasseurs <small>2020</small></span>
                    </div>
                </div>
                <div className='container-img'>
                    <div onClick={() => toogleSelect('three')} className='image-text'>
                        <div className='image-croper'>
                            <img src={Boutique} alt='miniature'/>
                        </div>
                        <span>la boutique <small>2019</small></span>
                    </div>
                    <div onClick={() => toogleSelect('four')} className='image-text'>
                        <div className='image-croper'>
                            <img src={clac} alt='miniature'/>
                        </div>
                        <span>clac <small>2021</small></span>
                    </div>
                </div>
                <div className='background'></div>
            </div>
        </StyledModalFooter>
    )
}
