import styled from "styled-components";

const StyledHeader = styled.header`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-title-modal{
        width: 300px;
        height: 125px;
        border-radius: 0px 0px 24px 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1{
            font-family: Amatic SC;
            font-style: normal;
            font-weight: normal;
            font-size: 36px;
            text-align: center;
            color: #FFFFFF;
            width: 60%;
        }
    }

    .wrapper-btn {
        padding: 8px;
        position: fixed;
        left: 28px;
        cursor: pointer;
        transition: 400ms;
        opacity: 0.6;
        &:hover {
            opacity: 1;
            transition: 400ms;
        }
        svg{
            width: 24px;
            height: 24px;
            color: #FFFFFF;
        }
    }

    .blue{
        background-color: #2C959C;
    }

    .red{
        background-color: #92041D;
    }

    .yellow{
        background-color: #F8AD3D;
    }

    @media (max-width: 500px){
        .container-title-modal{
            width: 200px;
            height: 90px;
            h1{
                font-size: 24px;
            }
        }

        .wrapper-btn{
            left: 10px;
            top: 24px;
        }
    }

`

export default StyledHeader;