import styled from "styled-components";

const StyledImageDesc = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 540px;
    background: rgba(0, 0, 0, 0.3);
    z-index: -2;
    margin: 48px 0px;

    .container{
        position: relative;
        overflow: hidden;
        width: 1200px;
        height: 100%;
        img{
            position: absolute;
            z-index: -1;
        }
        .text-container{
            position: absolute;
            width: 50%;
            backdrop-filter: blur(20px);
            padding: 24px;
            
            h3{
                font-family: Thasadith;
                font-style: italic;
                font-weight: normal;
                font-size: 24px;
                text-align: justify;
                color: #FFFFFF;
            }
            
            p{
                font-family: Thasadith;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                text-align: justify;
                
                color: #FFFFFF;
            }
        }

        .left{
            bottom: 48px;
            left: 0px;
            border-radius: 0px 12px 12px 0px;
        }

        .right{
            bottom: 48px;
            right: 0px;
            border-radius: 12px 0px 0px 12px;
        }

        .up{
            top: 48px;
            right: 0px;
            border-radius: 12px 0px 0px 12px;
        }

        .blue{
            background: rgba(44, 149, 156, 0.3);
        }

        .red{
            background: rgba(149, 4, 30, 0.3);
        }
        
        .yellow{
            background: rgba(250, 175, 62, 0.3);
        }
    }

    @media (max-width: 1200px){
        height: 400px;
        overflow: hidden;
        .container{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
            img{
                width: auto;
                height: 100%;
            }
        }
    }

    @media (max-width: 500px){
        .container{
            .text-container{
                width: 100%;
                padding: 24px 0px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                border-radius: 0px;
                h3{
                    margin-bottom: 12px;
                    text-align: center;
                }
                p{
                    width: 90%;
                }
            }
            .left,.right,.up{
                top: unset;
                border-radius: 0px;
                bottom: 0;
            }
        }
    }
`

export default StyledImageDesc