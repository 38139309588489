import React from 'react'
import { Landing } from '../components/Landing/Landing'

export const Home = () => {
    return (
        <>
            <Landing/>
        </>
    )
}
