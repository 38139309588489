import React from 'react'
import StyledModalWelcome from './StyledModalWelcome'
import { HiOutlineArrowRight } from 'react-icons/hi';

export const ModalWelcome = ({toggleModal , setIsOpenWelcome}) => {
    const handleSummary = () => {
        setIsOpenWelcome()
        toggleModal()
    }

    return (
        <StyledModalWelcome setIsOpenWelcome={setIsOpenWelcome}>
            <div className='wrapper-modal'>
                <h1>Si on doit parler d’enseignement, faisons-le avec l’apprenant</h1>
                <div className='separator'></div>
                <p>
                    <span>Apprendre</span> est un documentaire qui a comme objectif de mettre en avant la parole des élèves afin qu’ils puissent exprimer leurs besoins et leurs ressentis vis-à-vis de l’enseignement qu’on leur propose. Peu écouté quand il s’agit de changer l’école traditionnelle, il est temps d’écouter les principaux concernés
                    <br/>
                    <br/>
                    Le documentaire doit également montrer les différentes méthodes d’enseignements avec leurs défauts et leurs qualités. 
                    <br/>
                    <br/>
                    D’autres profils comme les enseignants, les parents d’élèves, membres du Ministère de l’Éducation Nationale et chercheurs alimenteront le débat avec leurs points de vue.
                </p>
                <div onClick={() => handleSummary()} className='wrapper-button'>
                    <h3>SOMMAIRE</h3>
                    <button><HiOutlineArrowRight/></button>
                </div>
            </div>
        </StyledModalWelcome>
    )
}
