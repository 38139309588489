import React from 'react'
import { Bio } from '../components/Bio/Bio'
import { Layout } from '../components/Layout'

export const Biographie = () => {
    return (
        <Layout>
            <Bio/>
        </Layout>
    )
}
