import React from 'react'
import StyledSequence from './StyledSequence'

export const Sequence = ({seq}) => {
    return (
        <StyledSequence title={seq.title}>
            <img className='svg-icon' src={seq.icon} alt='icon'/>
            <h3>{seq.title}</h3>
            <div className='separator'></div>
            <p>
                {seq.para}
            </p>
            <div className='container-img'>
                <img className='picture-theme' src={seq.img} alt='image'/>
            </div>
        </StyledSequence>
    )
}
