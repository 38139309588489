import React, {useContext, useEffect, useState} from 'react'
import StyledHeader from './StyledHeader'
import { GiHamburgerMenu } from 'react-icons/gi';
import { BurgerMenuContext } from '../../Context/BurgerMenuContext';
import { useLocation } from 'react-router-dom';

export const Header = () => {
    const[title ,setTitle ] = useState('')
    const[classname, setClassname ] = useState('')
    const location = useLocation()

    const {toggleModal} = useContext(BurgerMenuContext)

    useEffect(() => {
        if(location.pathname === '/note'){
            setTitle('NOTE D’INTENTION')
            setClassname('blue')
        }else if(location.pathname === '/chapitres'){
            setTitle('CHAPITRES')
            setClassname('blue')
        }else if(location.pathname === '/itw1' || location.pathname === '/itw2'){
            setTitle('INTERVIEWS')
            setClassname('red')
        }else if(location.pathname === '/pourquoi'){
            setTitle('POURQUOI 416 PROD?')
            setClassname('yellow')
        }else if(location.pathname === '/bio'){
            setTitle('À PROPOS DE YAMI')
            setClassname('yellow')
        }else if(location.pathname === '/sequences'){
            setTitle('TYPES DE SÉQUENCE')
            setClassname('red')
        }
    },[])

    return (
        <StyledHeader>
            <div className='wrapper-btn'>
                <GiHamburgerMenu onClick={toggleModal}/>
            </div>
            <div className={`container-title-modal ${classname}`}>
                <h1>{title}</h1>
            </div>
        </StyledHeader>
    )
}
