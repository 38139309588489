import styled from "styled-components";

const StyledItw1 = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon-intro{
        width: 84px;
        height: 84px;
        margin-top: 72px;
    }

    h1{
        font-family: Amatic SC;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        text-align: justify;
        color: #FFFFFF;
        margin-top: 12px;
    }

    .separator{
        width: 700px;
        height: 2px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 40px;
        margin: 60px 0px;
    }

    .text-intro{
        width: 700px;
        font-family: Thasadith;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        text-align: justify;
        line-height: 28px;
        color: #FFFFFF;
        margin-bottom: 60px;
    }

    .pagination{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        span{
            font-family: Thasadith;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            text-align: center;
            color: rgba(255, 255, 255, 0.5);
        }

        a{
            text-decoration: none;
            font-family: Thasadith;
            font-style: normal;
            font-weight: bold;
            line-height: 36px;
            font-size: 18px;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
        }
        .separator{
            width: 300px;
            margin: 12px 0px 36px 0px;
        }
    }

    @media (max-width: 1200px){
        .separator{
            width: 80%;
        }
        .text-intro{
            width: 80%;
        }
    }

    @media (max-width: 500px){
        .separator{
            width: 90%;
        }
        .text-intro{
            width: 90%;
            font-size: 18px;
        }
    }
`

export default StyledItw1