import styled from "styled-components";

const StyledModalFooter = styled.div`
    @keyframes openModal{
        0% {
            height: 90px;
        }

        100% {
            height: 724px;
        }
    }

    @keyframes openModalResponsive{
        0% {
            height: 90px;
        }

        100% {
            height: 924px;
        }
    }

    @keyframes closeModal{
        0% {
            height: 924px;
        }

        100% {
            height: 90px;
        }
    }

    @keyframes closeModalResponsive{
        0% {
            height: 724px;
        }

        100% {
            height: 90px;
        }
    }

    @keyframes hidden{
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }

    @keyframes visible{
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    padding-top: 24px;
    .container-video{
        padding-top: 24px;
        position: absolute;
        bottom: 60px;
        left: 0px;
        width: 100%;
        height: ${({isOpen}) => isOpen ? '724px' : '90px'};
        animation: 500ms ${({isOpen}) => isOpen ? 'openModal' : 'closeModal'};
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        background: rgba(196, 196, 196, 0.02);
        backdrop-filter: blur(100px);
        .first-place{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
        .container-icone{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background-color: #ffffff;
            border-radius: 6px 6px 0px 0px;
            img{
                width: 24px;
                height: 24px;
            }
        }
        .separator{
            width: 400px;
            height: 4px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 58px;
            margin-bottom: 12px;
        }
        .container-img{
            display: flex;
            .image-text{
                margin: 0px 12px;
                .image-croper{
                    width: 350px;
                    height: 197px;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid white;
                    border-radius: 12px;
                    cursor: pointer;
                    img{
                        width: 350px;
                        height: auto;
                    }
                }
                span{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    font-family: Amatic SC;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 36px;
                    color: #FFFFFF;
                    margin: 24px 0px;
                    small{
                        font-family: Amatic SC;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 24px;
                        color: #FFFFFF;
                        margin-bottom: 0px;
                    }
                }
            }
        }

        .clic{
            opacity: ${({isOpen}) => isOpen ? '0' : '1'};
            animation: 500ms ${({isOpen}) => isOpen ? 'hidden' : 'visible'};
            font-family: Thasadith;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            text-align: center;
            color: #FFFFFF;
            margin-bottom: 24px;
        }
    }

    @media (max-width: 800px){
        .container-video{
            overflow: ${({isOpen}) => isOpen ? 'auto' : 'null'};

            .separator{
                width: 100%;
            }
        }
        .container-img{
            flex-direction: column;
            .image-text{
                span{
                    font-size: 18px!important;
                    margin: 12px 0px!important;
                    small{
                        font-size: 18px!important;
                    }
                }
                .image-croper{
                    width: 250px!important;
                    height: 138px!important;
                    img{
                        width: 250px!important;
                        height: auto;
                    }
                }
            }
        }
    }
`

export default StyledModalFooter