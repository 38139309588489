import React, { useEffect, useState }from 'react'
import StyledModalVideo from './StyledModalVideo';
import { IoClose } from 'react-icons/io5'

export const ModalVideo = ({ number, setNumber }) => {

    const toggleModal = () => {setNumber('')}

    const [ frame, setFrame ] = useState('')
    useEffect(()  => {
        switch (number) {
            case 'one':
                setFrame('648274572')
            break;
            case 'two':
                setFrame('647348567')
            break;
            case 'three':
                setFrame('648267900')
            break;
            case 'four':
                setFrame('647356276')
            break;
            default:
            console.log(`Sorry, we are out of ${number}.`);
        }
    },[])

    return (
        <StyledModalVideo onClick={toggleModal}>
            <IoClose/>
            { frame !== '' &&
                <div className='wrapper-video'>
                    <div className="wistia_responsive_wrapper">
                        <iframe src={`https://player.vimeo.com/video/${frame}?autoplay=1&loop=1`} frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    </div>
                </div>
            }
        </StyledModalVideo>
    )
}
