import React from 'react'
import { Intent } from '../components/Intent/Intent'
import { Layout } from '../components/Layout'

export const Statement = () => {
    return (
        <Layout>
            <Intent/>
        </Layout>
    )
}
