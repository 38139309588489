import styled from "styled-components";

const StyledFooter = styled.footer`
    position: relative;
    .container-background-color{
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            text-align: center;
            font-family: Thasadith;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            text-align: justify;
            line-height: 28px;
            color: #FFFFFF;
        }
    }
    .blue{
        background: #2C959C;
    }

    .red{
        background: #90041D;
    }

    .yellow{
        background: #F5AA3C;
    }

`

export default StyledFooter