import React from 'react'
import StyledImageDesc from './StyledImageDesc'
import student from '../../Ressources/student.jpg'

export const ImageDesc = ({ param }) => {
    return (
        <StyledImageDesc>
            <div className='container'>
                <img src={param.img} alt='image-theme'/>
                <div className={`text-container ${param.classname}`}>
                    <h3>
                        {param.title}
                    </h3>
                    <p>
                        {param.para}
                    </p>
                </div>
            </div>
        </StyledImageDesc>
    )
}
