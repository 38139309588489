import styled from "styled-components";

const StyledBio = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 90px;

    img{
        width: 1000px;
        height: auto;
        margin: 48px 0px;
    }
    
    .separator{
        width: 30%;
        height: 2px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 40px;
        margin: 48px 0px 84px 0px;
    }

    .container-text{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.3);
        p{
            width: 800px;
            font-family: Thasadith;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            text-align: justify;
            line-height: 28px;
            color: #FFFFFF;
    
            span{
                font-style: italic;
                font-weight: normal;
                color: rgba(255, 255, 255, 0.5);
            }
    
            small{
                font-style: normal;
                color: rgba(248, 173, 62, 1);
            }
            margin: 24px 0px;
        }
    }

    @media (max-width: 1200px){
        img{
            width: 80%;
        }
        .separator{
            width: 40%;
        }
    }

    @media (max-width: 900px){
        .container-text{
            p{
                width: 90%;
                font-size: 18px;
            }
        }
    }
`

export default StyledBio