import React, {useContext} from 'react'
import { ModalLanding } from '../ModalLanding/ModalLanding'
import { BurgerMenuContext } from '../../Context/BurgerMenuContext'
import StyledChapter from './StyledChapter'

export const Chapter = () => {

    const {isOpen} = useContext(BurgerMenuContext)

    return (
        <StyledChapter>
            <p>
                Comme l’enseignement est un sujet chargé, le traiter avec un schéma intuitif pour le spectateur est nécessaire. Le documentaire se doit être digeste avec un découpage facilitant le visionnage. Le découpage en tranche d’âge est là pour rendre les épisodes indépendants, le spectateur étant libre de commencer par l’épisode auquel il s’identifie le plus vis-à-vis de sa génération.
                <br/>
                <span>
                    Cependant, le découpage n’est pas définitif et pourra s’adapter aux besoins du projet. Les questions ci-dessous ne sont pas exhaustives non plus. Ce sont des pistes de départ pour le développement du projet. Le documentaire aura pour objectif d’y donner des réponses ou un axe de réfléxion pertinent.
                </span>
            </p>
            <div className='separator-intro'></div>
            <div className='container-chapter'>
                <h1>CHAPITRE 1<span>L’enseignement chez l’enfant</span></h1>
                <div className='separator-chapter'></div>
                <ul>
                    <li>Quels sont les besoins de l’enfant en terme d’enseignement?</li>
                    <li>Les écoles primaires en pleine nature, bonne idée ?</li>
                    <li>Si l’on applique les connaissances que l’on a en neurologie et psychologie de l’enfant, nos a priori sur leurs besoins sont-ils toujours justes?</li>
                    <li>Quelle est la place, aujourd’hui, d’un parent dans l’enseignement d’un enfant?</li>
                    <li>Pourquoi l’école devient-elle si sérieuse à partir du collège? Est-ce vraiment bénéfique pour l’enfant?</li>
                    <li>Devons nous garder la gamification dans notre enseignement?</li>
                    <li>Quelles sont les origines de nos méthodes d’enseignement actuelles ?</li>
                    <li>Devons nous introduire des matières différentes?</li>
                    <li>Les notes, une fausse bonne idée?</li>
                    <li>Les bienfaits de l’improvisation à l’école</li>
                    <li>Pourquoi est-ce devenu tabou de parler de sexualité et de l’anatomie du corps humain?</li>
                    <li>Comment faire pour que la précarité de l’élève n’impacte plus sa scolarité et son enseignement?</li>
                </ul>
            </div>
            <div className='container-chapter'>
                <h1>CHAPITRE 2<span>L’adolescent apprend à être un adulte.</span></h1>
                <div className='separator-chapter'></div>
                <ul>
                    <li>Rapport à l’autorité (professeurs) : une relation dominant/dominé néfaste ?</li>
                    <li>Philosophie trop tardive ?</li>
                    <li>Professeurs en mauvaise postures, en manque de ressources et forcé de respecter un programme... Quel est l’impact sur l’enseignement effectué?</li>
                    <li>L’impact d’internet sur l’enseignement?</li>
                    <li>Comment être écouté, compris  par ses parents et ses enseignants vis-à-vis de ses centres d’intérêts?</li>
                    <li>L’élève doit-il vraiment choisir une seule et unique voie pour toute sa vie? La réorientation scolaire/professionnelle est elle assez valorisée?</li>
                    <li>Pourquoi le redoublement n’est-il pas mis en valeur?</li>
                    <li>Les élèves grandissant sans aller à école, une mauvaise idée?</li>
                    <li>Apprendre à être adulte à l’école?</li>
                    <li>Devons nous enseigner des choses telles que la cuisine ou le ménage?</li>
                    <li>Comment se sentir à sa place à l’école quand on n’y voit pas l’intérêt?</li>
                    <li>Les parents savent-ils toujours vraiment ce dont ont besoin les enfants?</li>
                </ul>
            </div>
            <div className='container-chapter'>
                <h1>CHAPITRE 3<span>L’adulte et l’enseignement</span></h1>
                <div className='separator-chapter'></div>
                <ul>
                    <li>Les études supérieures, meilleur endroit pour décrocher quand les intervenants ne regardent plus les élèves?</li>
                    <li>Le cerveau adulte est-il apte à ingérer des tonnes de connaissances d’un coup? Pourquoi les premières notions de spécialisation sont-elle si tardives ? </li>
                    <li>Que vallent les capacités du cerveau adulte? Sa plasticité est-elle suffisante pour apprendre ou réapprendre facilement?</li>
                    <li>Le retour de la gamification (enseignement par les jeux) se fait de plus en plus remarquer à l’âge adulte, une idée revitalisante?</li>
                    <li>Les adultes utilisent-ils pleinement ce qu’ils ont appris à l’école ou pendant leurs études supérieures?</li>
                    <li>Quels sont les besoins d’un adulte quand il apprend ? Est-il plus à l’aise avec les notes?</li>
                    <li>Se former pour changer de voie ? Mal vu et/ou inaccessible?</li>
                    <li>Une fois dans la vie active, rares sont ceux qui sont réellement prêts, quel est ce bagage qu’il nous manque?</li>
                    <li>Les filières professionnelles sont dénigrées, n’ont-elles pas pourtant une longueur d’avance sur les autres de part leur professionnalisation anticipée?</li>
                    <li>Quelles sont les différences entre l’enseignement à l’école et la vie active ? </li>
                    <li>Est-ce vraiment mal de garder son âme d’enfant? N’est-elle pas la partie de nous la plus apte à nous faire apprendre de nouvelles choses?</li>
                </ul>
            </div>
            <div className='container-chapter'>
                <h1>CHAPITRE 4<span>Structures d’apprentissage</span></h1>
                <div className='separator-chapter'></div>
                <ul>
                    <li>Est-ce que l’école est réellement un endroit pour « travailler » ? Et non pas juste pour apprendre?</li>
                    <li>Les méthodes d’enseignement traditionnel ont-elles encore du sens? </li>
                    <li>Pourquoi les écoles alternatives sont elles de plus en plus en vogue? Tout miser sur la volonté de l’élève, est-ce là la solution?</li>
                    <li>Que devons nous apprendre aux enseignants ? Comment les former ? </li>
                    <li>Devons nous avoir des écoles hybrides mélangant liberté (matière/thèmes étudiés) et obligations (devoir/notations/horaires/projets) ?</li>
                    <li>“Dégraisser le mammouth” de l’Éducation Nationale, mauvaise volonté de l’État ou réel casse-tête?</li>
                    <li>Changer les matières imposées ? Quelles sont les difficultés d’une telle mise en place à l’échelle d’un pays?</li>
                    <li>Pourquoi est-ce urgent de réformer le métier d’enseignant ?</li>
                    <li>Pourquoi ces problématiques sur l’enseignement sont-elles de plus en plus récurrentes dans les débats médiatiques ?</li>
                    <li>Le secteur public a-t-il encore un avenir? Comment peut-on le sauver?</li>
                    <li>Pouvons nous nous inspirer des méthodes appliquées dans les pays étrangers ?</li>
                    <li>Que se passera-t-il si nous ne changeons rien?</li>
                    <li>Internet et les nouvelles formes d’apprentissage, quels en sont les risques et bénéfices?</li>
                    <li>À quel besoin veulent répondre les écoles alternatives? Comment les soutenir? Sur quoi basent-elles leurs méthodes?</li>
                    <li>Que deviennent les élèves d’écoles alternatives?</li>
                    <li>Le Ministère de l’Éducation s’intéresse-t-il à ces nouvelles écoles et à leurs méthodes?</li>
                </ul>
            </div>
            {   isOpen && 
                <ModalLanding/>
            }
        </StyledChapter>
    )
}
