import React, { useEffect } from 'react'
import { Footer } from './Footer/Footer'
import { Header } from './Header/Header'

export const Layout = ({children}) => {
    const ScrollToTop = () => window.scrollTo(0, 0);

    useEffect(() => {
        ScrollToTop()
    },[])
    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    )
}
