import React from 'react'
import { Itw2 } from '../components/Itw2/Itw2'
import { Layout } from '../components/Layout'

export const Interviews2 = () => {
    return (
        <Layout>
            <Itw2/>
        </Layout>
    )
}
