import styled from "styled-components";

const StyledSequence = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 72px;

    .svg-icon{
        width: ${({title}) => title === 'INTERVIEWS' ? '60px' : '90px'};
        height: ${({title}) => title === 'INTERVIEWS' ? '60px' : '90px'};
        margin-left: ${({title}) => title === 'INTERVIEWS' ? '17px' : 'null'};;
    }

    h3{
        font-family: Amatic SC;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        color: #FFFFFF;
        margin-top: 8px;
    }

    .separator{
        width: 600px;
        height: 2px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 40px;
        margin: 36px 0px;
    }

    p{
        width: 600px;
        font-family: Thasadith;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        text-align: justify;
        line-height: 28px;
        color: #FFFFFF;
        white-space: pre-line;
    }

    .container-img{
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 72px;
        .picture-theme{
        }
    }

    @media (max-width: 1300px){
        .picture-theme{
            width: 80%;
            height: auto;
        }
    }

    @media (max-width: 800px){
        .separator{
            width: 80%;
        }
        p{
            width: 80%;
            font-size: 18px;
        }
    }

    @media (max-width: 800px){
        .separator{
            width: 80%;
        }
        p{
            width: 80%;
            font-size: 16px;
        }
        .picture-theme{
            width: 100%;
            height: auto;
        }
    }
`

export default StyledSequence