import styled from "styled-components";

const StyledChapter = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        width: 60%;
        font-family: Thasadith;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        text-align: justify;
        line-height: 28px;
        color: #FFFFFF;
        margin:80px 0px 0px 0px;
        span{
            font-family: Thasadith;
            font-style: italic;
            font-weight: thin;
            font-size: 24px;
            text-align: justify;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    .separator-intro{
        width: 60%;
        height: 2px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 40px;
        margin-top: 24px;
    }

    .container-chapter{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.3);
        padding: 60px 0;
        margin-top: 48px;
        h1{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-family: Thasadith;
            font-style: normal;
            font-weight: normal;
            font-size: 18px;
            color: #FFFFFF;
            width: 217px;
            text-align: center;
            span{
                margin-top: 8px;
                width: 70%;
                font-family: Thasadith;
                font-style: italic;
                font-weight: normal;
                font-size: 24px;
                text-align: center;
                color: rgba(255, 255, 255, 0.5);
            }
        }
        .separator-chapter{
            height: 124px;
            width: 0.5px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 23.5px;
            margin: 0 60px;
        }

        ul{
            width: 500px;
            font-family: Thasadith;
            font-style: italic;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            margin: 6px 0;
            text-align: justify;
            padding-left: 24px;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    @media (max-width: 1200px){
        .container-chapter{
            flex-direction: column;
            .separator-chapter{
                width: 500px;
                height: 2px;
                margin: 24px 0px;
            }
        }
    }

    @media (max-width: 700px){
        p{
            width: 90%;
            font-size: 18px;
            span{
                font-size: 18px;
            }
        }

        .container-chapter{
            ul{
                width: 90%;
            }
            .separator-chapter{
                width: 90%;

            }
        }
    }
`

export default StyledChapter