import styled from "styled-components";

const StyledModalLanding = styled.div`

    @keyframes openNav{
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: ${({newlocation}) => newlocation === '/' ? 'space-between' : 'center'};
    position: fixed;
        background: rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(20px);
    animation: 500ms  openNav;

    svg{
        position: absolute;
        top: 42px;
        left: 30px;
        width: 36px;
        height: 36px;
        color: rgba(255, 255, 255, 0.5);
        cursor: pointer;
        transition: 300ms;
    }

    svg:hover{
        color: #FFFFFF;
    }

    .container-title-modal{
        width: 300px;
        height: 150px;
        background: #FFB23F;
        border-radius: 0px 0px 36px 36px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h1{
            font-family: Amatic SC;
            font-style: normal;
            font-weight: normal;
            font-size: 50px;
            text-align: center;
            color: #FFFFFF;
        }

        span{
            font-family: Caveat;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            text-align: center;
            color: #2C959C;
            margin-top: 12px;
        }

        small{
            font-family: Thasadith;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            text-align: center;
            color: #FFFFFF;
            margin-top: 12px;
        }
    }

    nav{
        display: flex;
        flex-direction: column;
        align-items: normal;
        justify-content: space-between;
        width: 1000px;
        height: 350px;

        ul{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                width: 40%;
                font-family: Amatic SC;
                font-style: normal;
                font-weight: thin;
                font-size: 32px;
                text-align: center;
                text-transform: capitalize;
                color: #FFFFFF;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                z-index: 1;
                text-decoration: none;
    
                div{
                    position: absolute;
                    top: -32.5;
                    width: 84px;
                    height: 60px;
                    background: rgba(196, 196, 196, 0.1);
                    backdrop-filter: blur(12px);
                    border-radius: 12px;
                    z-index: -1;
                    transition: 300ms;
                }
            }

            a:hover{
                div{
                    top: -32.5;
                    width: 200px;
                    height: 200px;
                    border-radius: 24px;
                }

                .red{
                    background: rgba(149, 4, 30, 0.5);
                }

                .blue{
                    background: rgba(44, 149, 156, 0.5);
                }

                .yellow{
                    background: rgba(248, 173, 61, 0.5);
                }
            }
        }
    }

    .container-footer{
        background-color: #2C959C;
        height: 100px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        h2{
            font-family: Amatic SC;
            font-style: normal;
            font-weight: normal;
            font-size: 40px;
            color: #FFFFFF;
        }
    }

    @media (max-width: 960px){
        svg{
            top: 12px;
            left: 12px
        }
        .container-title-modal{
            width: 200px;
            height: 90px;
            h1{
                font-size: 24px;
            }
            span{
                font-size: 18px;
            }
        }
        nav{
            width: 100%;
            flex-direction: row;
            justify-content: center;
            margin: 30% 0px;
            ul{
                width: 40%;
                height: 300px;
                flex-direction: column;
                a{
                    width: 100%;
                    font-size: 20px;
                    div{
                        width: 48px;
                        height: 36px;
                        border-radius: 6px;
                    }
                }
            }
        }
        .container-footer{
            h2{
                font-size: 18px;
            }
        }
    }
`

export default StyledModalLanding;