import styled from "styled-components";

const StyledLanding = styled.section`
@keyframes openBorder{
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes closeBorder{
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}



    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .border {
        height: 160px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: 500ms ${({isOpenWelcome}) => isOpenWelcome ? 'closeBorder' : 'openBorder'};
        opacity: ${({isOpenWelcome}) => isOpenWelcome ? '0' : '1'};
    }

    h1{
        font-family: Amatic SC;
        font-style: normal;
        font-weight: bold;
        font-size: 72px;
        line-height: 100%;

        text-align: center;

        color: #FFFFFF;
        display: flex;
        flex-direction: column;

        span{
            font-family: Amatic SC;
            font-style: normal;
            font-weight: normal;
            font-size: 24px;
            color: #2C959C;
            line-height: 24px;
        }
    }

    h2{
        font-family: Amatic SC;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 100%;

        text-align: center;

        color: #2C959C;
        display: flex;
        flex-direction: column;

        span{
            font-family: Amatic SC;
            font-style: normal;
            font-weight: bold;
            font-size: 52px;
            line-height: 100%;

            text-align: center;

            color: #FFFFFF;
        }
    }

    .background-girl{
        display: flex;
        overflow: hidden;
        justify-content: center;
        height: calc(100% - 180px);
        background-image: url('../../images/girl.png');
        background-position: center center;
        background-size: cover;

        .bg-girl{
            min-height: 100%;
            z-index: -1;
        }

        .deco-bar{
            width: 4px;
            height: 120%;
            left: 0px;
            background: rgba(255, 255, 255, 0.5);
            border-radius: 40px;
            position: absolute;
        }

        form{
            padding-left: 42px;
            margin: 0px 72px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            img{
                width: 500px;
                height: auto;
            }

            p{
                font-family: Thasadith;
                font-style: italic;
                font-weight: thin;
                font-size: 23px;
                line-height: 100%;

                text-align: justify;

                color: #FFB23F;
                margin: 24px 48px;
            }

            label {
                font-family: Thasadith;
                font-style: normal;
                font-weight: thin;
                font-size: 18px;
                line-height: 100%;
                color: #FFFFFF;
                margin-left: calc(200px / 2);
            }

            .input-form{
                display: flex;
                align-items: center;
                justify-content: start;
                input[type=password] {
                    width: 180px;
                    height: 30px;
                    background: #464141;
                    border: 1px solid #FFFFFF;
                    border-radius: 6px;
                    margin-left: 48px;
                    color: white;
                    user-select: none;
                    margin-top: 6px;
                    padding: 0px 12px;
                }

                input[type=password]:focus {
                    outline: none;
                }

                button{
                    width: 24px;
                    height: 24px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    border-radius: 100%;
                    border: 1px solid white;
                    margin-top: 6px;
                    margin-left: 12px;
                    cursor: pointer;
                    svg{
                        color: white;
                    }
                }
            }
        }
    }
    
    @media (max-width: 800px){
        .deco-bar{
            display: none;
        }

        .background-girl{
            form{
                width: 100%;
                height: 300px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-left: 0px;
                margin: 0px;
                background: rgba(196, 196, 196, 0.02);
                backdrop-filter: blur(40px);
                padding: 24px 0px;
                img{
                    width: 250px;
                }
                label{
                    margin-left: 0px;
                }
                p{
                    font-size: 18px;
                    text-align: center;
                }
            }
        }
    }
`

export default StyledLanding