import styled from "styled-components";

const StyledIntent = styled.section`
display: flex;
align-items: center;
justify-content: center;
background: rgba(0, 0, 0, 0.3);
margin-top: 52px;

    p{
        width: 36%;
        font-family: Thasadith;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        text-align: justify;
        line-height: 28px;
        color: #FFFFFF;
        margin: 36px 0px;
    }

    i{
        color: rgba(255, 255, 255, 0.5);
        font-style: italic;
    }

    @media (max-width: 1500px){
        p{
            width: 60%;
        }
    }

    @media (max-width: 800px){
        p{
            width: 90%;
            font-size: 18px;
        }
    }

    @media (max-width: 450px){
        p{
            width: 90%;
            font-size: 14px;
        }
    }
`

export default StyledIntent