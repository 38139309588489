import React, { useContext, useState } from 'react'
import StyledBio from './StyledBio'
import { ModalLanding } from '../ModalLanding/ModalLanding';
import { BurgerMenuContext } from '../../Context/BurgerMenuContext';
import timeline from '../../Ressources/timeline.png'
import bloc1 from '../../Ressources/bloc 1.png'
import bloc2 from '../../Ressources/bloc 2.png'

export const Bio = () => {

    const { isOpen } = useContext(BurgerMenuContext)

    return (
        <StyledBio>
            <img src={timeline} alt='timeline-yami'/>
            <div className='separator'></div>
            <div className='container-text'>
                <p>
                    <span>Cette section du dossier n'est pas essentielle à sa compréhension mais sa lecture aidera à comprendre pourquoi je suis intimement lié à cette série documentaire et comment chacun des choix de mon parcours scolaire et professionnel ont nourris ce projet.</span>
                    <br/>
                    <br/>
                    Malgré le fait que j'étais dispersé et très bavard à l’école, je n'arrivais pas à exprimer ce que je voulais faire dans la vie. L'angoisse de ne pas être pris au sérieux ni écouté par mes parents ou les professeurs me découragait instantanément. Alors je ne disais pas grand chose et j’attendais. J'attendais qu’on me comprenne ou que l'école se termine pour faire ce que je voulais : <small>raconter des histoires.</small> Au lycée on me met en filière technologique : c’était ça ou redoubler.
                    <br/>
                    <br/>
                    Mon père, à cette époque, était universitaire. Mon grand frère et ma grande soeur excellaient dans leurs études. Et puis il y avait moi qui, pour l’école, étais mauvais élève. C’est au skatepark que je m’évadais pour éviter la dépression.
                    Il y a dix ans, je sortait ma première vidéo de skate sur youtube parmis une longue série. Je cadrais et montais pour mes amis.
                    <br/>
                    <br/>
                    En terminale, je deviens soudainement un des meilleurs de ma classe en économie/droit et le meilleur en philosophie. Le cancre obtiens des notes excellentes. Pendant un cours d’économie/droit, l’enseignante me prend à part, me parle de mes mécaniques et m’explique pourquoi elles ne s’adaptaient pas aux matières classique.
                    <br/>
                    <br/>
                    Réunion parents/professeurs avec cette même enseignante. L'angoisse monte. Mais au contraire de mes habitudes, tout se passe très bien. Je reçois beaucoup de compliments et d’encouragements face à mes quelques résultats positifs. Ma mère verbalise sa joie en sortant <span>"Je suis fière de toi"</span>. Est-ce que j'avais déjà entendu ces mots venant d'elle? En tout cas, ils étaient sincères.
                    <br/>
                    <br/>
                    Ce jour là c’est un déclic que m’offre cette enseignante et il fait des étincelles. Des étincelles qui rallument un brasier éteint depuis longtemps : <span>Ma confiance en moi.</span>
                    <br/>
                    <br/>
                    C’est cette même étincelle qui me pousse à quitter la classe préparatoire économique pour me lancer dans mes projets de courts métrages. Je commence un moyen métrage que j’auto-finance et qui sortira deux ans plus tard.
                </p>
            </div>
            <img src={bloc1} alt='bloc-image'/>
            <div className='container-text'>
                <p>
                    Après avoir tourné pas mal de projets avec les moyens du bord et trouvé un CDI en tant que cadreur/monteur dans une école de danse, je commence à écrire un long métrage sur les enseignants.
                    <br/>
                    <br/>
                    Début Mars 2021 je tombe sur une annonce : L’Atelier 7, résidence de création audiovisuelle lancée par Les Parasites, démarre sa deuxième édition. Je postule. 400 candidatures, avec deux phases de séléction : je termine dans les 24 personnes séléctionnées pour passer un mois dans un ancien monastère  à faire des films.
                    <br/>
                    <br/>
                    L’Atelier 7 c’est une école démocratique où chaque semaine on avait des “assemblées démocratiques” pendant lesquelles on faisait le point sur tout ce qui allait ou non. Il n’y avait aucune contrainte à part ne pas prendre des douches avec le matériel. On était libre de faire ce qu’on voulait, même rien. Et c’est ça qui a créé l’émultion. 
                    Là bas nous étions nous mêmes tout le temps, libre de créer à notre manière. J’y ai réalisé deux courts métrages tout en retravaillant mon long métrage sur les enseignants.
                </p>
            </div>
            <img src={bloc2} alt="image bloc"/>
            <div className='container-text'>
                <p>
                    <small>”J’aurais tellement aimé apprendre comme ça à l’école”</small>
                    <br/>
                    <br/>
                    Je me suis répété ça tout les jours. 
                    <br/>
                    <br/>
                    Rares ont été les moments où je me sentais vraiment moi même et dans un environnement qui me plaisait. Ce mois à l’Atelier 7 a été un pur bonheur car je me sentais proche de ce que je désire faire de ma vie. En rentrant, je savais qu’il était question de seulement quelques semaines avant que je quitte mon travail et mon appartement pour me lancer à fond dans la réalisation de mes idées.
                    <br/>
                    <br/>
                    Après sept années de projets personnels, je compte bien commencer à porter professionnellement ma casquette d’auteur réalisateur. 
                    Démarrer avec <span>“Apprendre”</span> est devenu une évidence car ce projet a beaucoup de sens pour moi. Tant par rapport à mon  parcours qu’aux valeurs que je prône.
                    <br/>
                    <br/>
                    J’ai très vite senti que ce projet nécessitera tout mon temps et mobilisera toutes mes ressources. Fidèle à mon intuition, je quitte alors sans hésitation mon emploi et mon appartement pour me consacrer pleinement à ce nouveau projet.
                </p>
            </div>
            {  isOpen  && 
                <ModalLanding/>
            }
        </StyledBio>
    )
}
