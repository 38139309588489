import React from 'react'
import { useContext } from 'react'
import { BurgerMenuContext } from '../../Context/BurgerMenuContext'
import { ModalLanding } from '../ModalLanding/ModalLanding'
import StyledIntent from './StyledIntent'

export const Intent = () => {

    const {isOpen} = useContext(BurgerMenuContext)

    return (
        <StyledIntent>
            <p>
            Les premières idées de ce documentaire naissent avec l’écriture de mon long métrage sur le métier d’enseignant. En retravaillant mon axe, j’en ai conclu que traiter la difficulté du métier d’enseignant et montrer la complexité de l’enseignement sous toutes ses formes n’est pas possible en 1h30. Je choisis alors de me concentrer sur le métier d’enseignant pour le long métrage. En ce qui concerne l’élève et les méthodes d’enseignement, je décide d’y consacrer une œuvre à part entière : <i>Un documentaire</i>.
                <br/>
                <br/>
                Le système scolaire actuel fait preuve de réussite mais égalements d’échecs. De beaucoup d’échecs. Nombreux sont les élèves en décrochage. Mais comme ce n’est pas suffisant, plusieurs statistiques sont en hausse : le taux d’analphabètes, les élèves déscolarisés, le nombre d’enfants en dépression, les démissions d’enseignants, les parents qui perdent le contrôle... Le niveau global des élèves est également en chute libre, la France perd des places dans le classement Européen, etc.
                <br/>
                <br/>
                Je m’estime chanceux d’avoir eu en terminale une enseignante qui m’a éclairé sur mes difficultés. Cette année là je passe de l’élève en dessous de la moyenne au meilleur de la classe en Philosophie et en Économie/Droit. J’ai du mal à y croire, c’était trop facile pour être vrai. Cette enseignante a bien compris la situation et me l’a expliquée : je n’étais pas un cas perdu comme certains enseignants ont pu le dire. Je n’avais juste pas pu exploiter <i>mes</i> mécaniques. En revanche, ces deux nouvelles matières y faisaient appel et c’est pourquoi je m’impliquais naturellement.
                <br/>
                <br/>
                Ce documentaire est là pour transmettre ce message aux élèves qui sont, comme moi il y a dix ans, en difficulté et pensent être des cas perdus. Laisser un élève se faire rabaisser ou se rabaisser lui-même à ce point ne devrait même pas être envisageable. Par qui que ce soit. 
                Nos connaissances en neurologie, neuropsychologie et en sciences de l’éducation évoluent constamment. L’idée de QI se déconstruit petit à petit pour laisser place à des hypothèses sur le fait que l’intelligence se divise en plusieurs branches. Je suis convaincu que chaque enfant est capable d’exceller dans sa propre voie si on lui permet d’évoluer dans le bon environnement.
                <br/>
                <br/>
                Pour cela, encore faudrait-il qu’il soit écouté correctement. Un sentiment général reigne dans la cour de récréation : les élèves ne se sentent ni écoutés, ni compris. S’ajoute à cela l’arrivée d’internet où toute connaissance est disponible en quelques clics. Pour les élèves, l’intérêt porté à l’école devient aussi fragile qu’une mine de critérium. Pourquoi rester à l’école si l’enseignement en autodidacte est possible? 
                S’il ne fait pas preuve d’ingéniosité dans ses cours, l’enseignant devient une figure d’autorité chargée de garder les élèves jusqu’à la fin de l’heure. Mais bien trop souvent, la formation et les outils donnés à un enseignant ne suffisent pas pour faire face à une trentaine d’élèves démotivés par l’école. 
                Souvent, c’est le début d’un cercle vicieux où plus personne ne se comprend : l’élève n’aime pas le professeur et se braque, l’enseignant n’arrive plus à gérer ses élèves et  s’énerve peu à peu, les parents deviennent hostiles et accusent l’école, l’élève et les parents ne se comprennent pas... 
                Mais si on prend du recul, ces trois parties prenantes de l’éducation ont le même souhait : que l’élève entre dans la vie active avec un bon bagage. 
                <br/>
                <br/>
                Ce documentaire est également là pour donner la parole à ces personnes, en particulier l’élève. Lui qui est au cœur de ce système scolaire mais le dernier à être écouté. Je veux donner la parole à cet élève à qui on ne donne pas la place de s’exprimer quand il s’agit de prendre des décisions qui le concernent. On écoute parfois les enseignants, mais c’est à se demander si leurs souhaits sont vraiment pris en compte. Eux qui veulent aider les élèves mais à qui l’État enlève des ressources importantes, humaines ou matérielles.
                Qui en assume également les conséquences? Les élèves. 
                <br/>
                <br/>
                S’ajoute à ça un autre problème : l’égalité des chances. L’école d’aujourd’hui creuse l’écart social, les élèves en situation de précarité arrivent à l’école déjà chargés de difficultés. Sans écoute et sans compréhension de leur condition, c’est une épée de Damoclès qui flotte au-dessus de leur tête. Les parents ne pouvant souvent pas les aider pour les devoirs (temps, barrière de la langue ou du fait de leurs propres lacunes), l’école représente le dernier espoir pour sortir l’enfant de cette situation précaire. Si l’enseignant laisse de côté l’enfant et/ou le rabaisse, c’est la descente aux enfers en trajet direct pour l’élève.
                <br/>
                <br/>
                Beaucoup de questions se posent autour de cet échec scolaire de masse, notamment celle des méthodes d’enseignement. Qu’elles soient à l’école classique, en école alternative ou sans école en autodidacte, je compte bien les étudier au travers de ce documentaire avec toujours un seul et même objectif : mettre en avant la parole de l’apprennant. Toute personne devrait pouvoir apprendre selon ses besoins et ses capacités. 
                <br/>
                <br/>
                Chacun aura également la possibilité de se reconnaitre dans ce documentaire, que ce soit au travers des élèves, enseignants ou parents d’élèves et prendre conscience que l’écoute de l’élève ainsi qu’une communication claire entre tout les acteurs de l’enseignement est primordiale pour réussir à former correctement les prochaines générations.
            </p>
            { isOpen && 
                <ModalLanding/>
            }
        </StyledIntent>
    )
}
