import React from 'react'
import { Itw1 } from '../components/Itw1/Itw1'
import { Layout } from '../components/Layout'

export const Interviews1 = () => {
    return (
        <Layout>
            <Itw1/>
        </Layout>
    )
}
