import img1 from '../Ressources/student.jpg'
import img2 from '../Ressources/desco.jpg'
import img3 from '../Ressources/prof.jpg'
import img4 from '../Ressources/wave.png'
import img5 from '../Ressources/parents.jpg'
import img6 from '../Ressources/neurologue.jpg'
import img7 from '../Ressources/ministère.jpg'
import img8 from '../Ressources/Blanquer.jpg'
import img9 from '../Ressources/recteur.jpg'
import img10 from '../Ressources/Atelier7.jpg'
import img11 from '../Ressources/Guillaume.jpg'
import img12 from '../Ressources/JEANNE.jpg'
import img13 from '../Ressources/Olivier.jpg'
import img14 from '../Ressources/livres.jpg'
import img15 from '../Ressources/chercheur.jpg'
import img16 from '../Ressources/atd.png'
import img17 from '../Ressources/action-enfance.png'
import img18 from '../Ressources/directeur.jpg'
import img19 from '../Ressources/educ.jpg'
import img20 from '../Ressources/drapeaux.jpg'

export default {
    image1: {
        img: img1,
        classname: 'left blue',
        title: 'Des élèves de tous les âges',
        para: 'De l’école primaire aux études supérieures, notre point de vue et notre avis sur l’apprentissage évolue sans cesse. Mais écoutons nous assez les principaux concernés quand on parle d’enseignement?'
    },
    image2: {
        img: img2,
        classname: 'left red',
        title: 'Élèves descolarisés',
        para: 'Les élèves exclus ou n’allant pas à l’école sont de plus en plus nombreux. Parfois poussés vers la porte de sortie, parfois trop loin de la porte d’entrée. Que ressentent-ils et qu’aimeraient-ils avoir comme solutions de secours?'
    },
    image3: {
        img: img3,
        classname: 'right yellow',
        title: 'Des enseignants',
        para: 'Pour parler de leur métier, de leurs besoins, des difficultés qu’ils y rencontrent et de ce qu’il faut changer au plus vite. L’attractivité de ce métier est en chute libre, si l’on souhaite avoir de nouvelles générations d’enseignants, répondre à ces questions devient urgent.'
    },
    pres1: {
        img: img4,
        classname: 'yellow',
        title: 'Assia Kaddour',
        para: 'Mon enseignante d’économie/droit en terminale. C’est elle qui décèle mes mécaniques et qui me pousse à avoir confiance en moi. Son point de vue, ses méthodes et la personne qu’elle est derrière son statut d’enseignante apporteront des raisonnements pertinents au documentaire.'
    },
    image4: {
        img: img5,
        classname: 'right red',
        title: 'Des parents d’élèves',
        para: 'Pour parler de leurs inquiétudes, leurs doutes et leur souhait vis-à-vis de l’apprentissage de leur enfant. Il est important de parler de l’incompréhension entre parents et enfants'
    },
    image5: {
        img: img6,
        classname: 'right blue',
        title: 'Des neurologues',
        para: 'L’application de nos connaissances sur le cerveau humain et son fonctionnement durant l’apprentissage pourrait améliorer significativement nos méthodes d’enseignement. Pourtant l’Éducation Nationale y semble réticente.'
    },
    image6: {
        img: img7,
        classname: 'left yellow',
        title: 'Ministère de l’Éducation Nationale',
        para: 'Il est courant d’entendre des critiques envers l’institution, j’aimerais également lui donner la parole. Il me parait essentiel d’étudier son fonctionnement.'
    },
    pres2: {
        img: img8,
        classname: 'yellow',
        title: 'Jean-Michel Blanquer',
        para: 'Ministre de l’Éducation, j’aimerais échanger avec lui pour avoir son point de vue, les problématiques de son métier, lui qui fait parti des décisionnaires quand il s’agit de faire bouger l’Éducation Nationale.'
    },
    pres3: {
        img: img9,
        classname: 'yellow',
        title: 'Bernard Toulemonde',
        para: 'Ancien recteur et ancien membre de plusieurs cabinet ministériels. Père de l’expression “le mamouth” en parlant de l’Éducation Nationale. Co-auteur du livre “Et si on tuait le mammouth” que je lis actuellement.'
    },
    image7: {
        img: img10,
        classname: 'up red',
        title: 'Écoles alternatives',
        para: 'De Montessori à Neill en passant par l’école 42 ou l’Atelier 7, les méthodes d’enseignement alternatives et les établissements qui les pratiquent sont de plus en plus populaires. Ces “écoles” sont-elles les précurseurs des écoles de demain?'
    },
    pres4: {
        img: img11,
        classname: 'red',
        title: 'Guillaume Desjardins',
        para: 'Un des membres parmis “Les Parasites”, groupe de vidéaste reconnus, il fonde l’Atelier 7 avec Reha Simon et Jérémy Bernard. Une école démocratique qui déconstruit totalement les schéma d’école de cinéma.'
    },
    pres5: {
        img: img12,
        classname: 'red',
        title: 'Jeanne Mastelan',
        para: "J’ai rencontré Jeanne à l’Atelier 7 et nous nous sommes liés d’amitié. Son profil m’intéresse beaucoup car elle a grandit sans aller à l’école. Elle met également à ma disposition un carnet d’adresse contenant beaucoup de profils très intéressant vis-à-vis des écoles alternatives."
    },
    pres6: {
        img: img13,
        classname: 'red',
        title: 'Olivier Dussausse',
        para: 'Co-créateur de l’école Georges Gusdorf, une école spécialisée dans les élèves à haut potentiel. C’est au 48H Film Project ainsi qu’au Téléthon du Cinéma que je rencontre Olivier et que l’on fait connaissance.'
    },
    image8: {
        img: img14,
        classname: 'left blue',
        title: 'Historiens et spécialistes en sciences de l’éducation',
        para: 'Des origines de notre système éducatif aux découvertes qui font émerger de nouvelles méthodes, mieux comprendre notre fonctionnement est une étape importante pour améliorer nos écoles.'
    },
    pres7: {
        img: img15,
        classname: 'blue',
        title: 'François Rastier',
        para: 'Directeur de recherches au CNRS, linguiste, auteur du livre “Apprendre pour transmettre” que je lis actuellement.'
    },
    pres8: {
        img: img16,
        classname: 'blue',
        title: 'ATD QuartMonde',
        para: 'Présent dans plus de 30 pays, ce mouvement mobilise des civils et des spécialistes pour donner les mêmes chances à tous les enfants, surtout ceux grandissant dans la précarité. Le fondateur d’ATD Quart monde disait : “Les méthodes les plus novatrices et les plus généreuse avaient été inspirées et inventées à partir des enfants démunis”.'
    },
    pres9: {
        img: img17,
        classname: 'blue',
        title: 'Action Enfance',
        para: 'Cette fondation récupère des enfants séparés de leurs parents par la justice et les place dans des foyers avec d’autres enfants. Quel est l’impact de cette nouvelle vie sur l’apprentissage de l’enfant?'
    },
    image9: {
        img: img18,
        classname: 'right yellow',
        title: 'Directrices/Directeurs d’école',
        para: 'La ou le directeur.rice d’établissement a une influence majeure sur le cadre scolaire des élèves, c’est un rôle dont on ne connait pas les rouages et qu’il est facile d’accabler. En leur tendant le micro, j’aimerais savoir à quel point ils peuvent influencer l’apprentissage d’un élève.'
    },
    image10: {
        img: img19,
        classname: 'right blue',
        title: 'Educateurs',
        para: 'Ces personnes voient et constastent des choses qui sont bien loin des réalités de l’école. Leur point de vue pourrait beaucoup aider à réformer nos méthodes d’enseignement.'
    },
    image11: {
        img: img20,
        classname: 'left yellow',
        title: 'Enseignants et élèves étrangers',
        para: 'De la Finlande au Japon, les autres pays utilsent des méthodes d’enseignement différentes des nôtres. La France essaye d’appliquer des méthodes du nord de l’Europe, mais l’écart culturel pose problème.  Enquêter sur les mentalités étrangères serait un plus conséquent dans la réfléxion que je veux livrer au spectateur.'
    },
}