import React from 'react'
import StyledImagePour from './StyledImagePour'
import liberty from '../../Ressources/liberty.jpg'

export const ImagePour = ({ param }) => {
    return (
        <StyledImagePour>
            <div className={`container-director-text ${param.classname}`}>
                <img src={param.img} alt='photo-theme'/>
                <div className='container-text'>
                    {param.para}
                    {   param.desc &&
                        <span>{param.desc}</span>
                    }
                </div>
            </div>
        </StyledImagePour>
    )
}
