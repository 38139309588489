import React, { useEffect, useState } from 'react'
import StyledFooter from './StyledFooter'
import { useLocation } from 'react-router-dom';
import { ModalFooter } from '../ModalFooter/ModalFooter'
import { ModalVideo } from '../ModalVideo/ModalVideo';

export const Footer = () => {

    const[classname, setClassname ] = useState('')
    const[isOpen, setIsOpen] = useState(false)
    const[number, setNumber] = useState('')
    const location = useLocation()

    useEffect(() => {
        if(location.pathname === '/note'){
            setClassname('blue')
        }else if(location.pathname === '/chapitres'){
            setClassname('blue')
        }else if(location.pathname === '/itw1' || location.pathname === '/itw2'){
            setClassname('red')
        }else if(location.pathname === '/pourquoi'){
            setClassname('yellow')
        }else if(location.pathname === '/bio'){
            setClassname('yellow')
        }else if(location.pathname === '/sequences'){
            setClassname('red')
        }
    },[])

    return (
        <StyledFooter >
            { location.pathname === '/pourquoi' || location.pathname === '/bio'
                ?

                    <ModalFooter isOpen={isOpen} setIsOpen={setIsOpen} setNumber={setNumber} number={number}/>

                :

                    null
            }
            {   number !== '' &&
                <ModalVideo number={number} setNumber={setNumber}/>
            }
            <div className={`container-background-color ${classname}`}>
                <p>Design - Yami Quinton | DevWeb - Sébastien Chapuy</p>
            </div>
        </StyledFooter>
    )
}
