import React, {useContext} from 'react'
import StyledWhy from './StyledWhy'
import { ModalLanding } from '../ModalLanding/ModalLanding';
import { BurgerMenuContext } from '../../Context/BurgerMenuContext';
import { ImagePour } from '../ImagePour/ImagePour';
import ArrayWhy from '../../Data/ArrayWhy';

export const Why = () => {

    const { isOpen } = useContext(BurgerMenuContext)

    return (
        <StyledWhy>
            <img className='logo-prod' src='../../images/logoProd.png' alt='logo 416 prod'/>
            <div className='separator'></div>
            <div className='container-text-why'>
                <p>
                    <span>“Partez avec des gens où vous sentez qu’ils vous comprennent et qu’ils comprennent votre projet”</span>
                    <br/>
                    <br/>
                    Cette phrase de Benjamin Parent, auteur réalisateur nous ayant donné une masterclass à l’Atelier 7, résonne dans ma tête depuis Juillet dernier. J’accorde beaucoup d’intérêt aux liens humain dans la sphère professionnelle, alors je garde fermement un objectif en tête  : <small>Trouver quelqu’un qui prendra à coeur le projet autant que moi.</small> 
                    <br/><br/>
                    En arrivant sur la page de 416 Prod, une chose me séduit avant même d’avoir regardé un des films : la place récurente de l’enfant. Je commence alors à me renseigner et à regarder les quelques documentaires que j’arrive à trouver.
                </p>
            </div>
            {
                ArrayWhy.map(param =>
                    <ImagePour param={param}/>
                )
            }
            <div className='container-text-why'>
                <p>
                    <small>“Et si un organisme permettait à tout ces passionnés de donner bénévolement des masterclass à des élèves sur leur temps libre?”</small>
                    <br/>
                    <br/>
                    C’est une évidence : <span>les films produits par 416 Prod m’inspirent.</span><br/>
                    C’est pour cette raison que c’est à votre intention que je fais ce dossier. Parce que je me sens concerné par les oeuvres que vous accompagnez. Parce que je me sens proche de ce que vous défendez. Si je souhaite developper mon projet avec vous, c’est aussi par intuiton. Une intuition qui me dit que vous pourriez l’accueuillir dans de bonnes conditions.
                    <br/>
                    <br/>
                    <span>Est-ce que je défend mon idée parceque c’est la mienne, ou parce que c’est celle que le film réclame?” (Benjamin Parent)</span>
                    <br/>
                    <br/>
                    La quantité de travail que requiert ce projet ne m’effraie pas car je sais que j’irai jusqu’au bout et que je m’y retrouverai totalement tant que je garde le propos intact.  Le challenge de ce documentaire et son sujet me transportent. 
                    <br/>
                    <br/>
                    J’espère que tout ces mots vous aideront à sentir la sincérité de ma démarche et la conviction que je mets dans ce projet.
                </p>
            </div>
            {  isOpen  && 
                <ModalLanding/>
            }
        </StyledWhy>
    )
}
