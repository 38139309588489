import React from 'react'
import StyledItw1 from './StyledItw1'
import { useContext } from 'react'
import { BurgerMenuContext } from '../../Context/BurgerMenuContext'
import { ModalLanding } from '../ModalLanding/ModalLanding'
import iconInter from '../../Ressources/interviews.svg'
import { ImageDesc } from '../ImageDesc/ImageDesc'
import ArrayItw from '../../Data/ArrayItw'
import { PresPerson } from '../PresPerson/PresPerson'
import { Link } from 'react-router-dom'

export const Itw1 = () => {

    const {isOpen} = useContext(BurgerMenuContext)

    return (
        <StyledItw1>
            <img className='icon-intro' src={iconInter} alt='icon'/>
            <h1>INTERVIEWS</h1>
            <div className='separator'></div>
            <p className='text-intro'>
                Une des problématiques de l’enseignement est que tout le monde ne percoit pas cet acte à travers le même prisme. Pour donner l’image la plus complète possible de ce que représente l’enseignement, il est impératif de donner la parole à des profils variés, ayant chacun un rôle différent dans le processus. La liste suivante n’est ni exhaustive ni définitive. Sa longueur et sa densité sont variables selon les besoins du documentaire. De plus, le temps de parole au montage sera réparti selon la pertinence des propos (mis à part pour les élèves qui sont le coeur du sujet).
            </p>
            <ImageDesc param={ArrayItw.image1}/>
            <ImageDesc param={ArrayItw.image2}/>
            <ImageDesc param={ArrayItw.image3}/>
            <PresPerson param={ArrayItw.pres1}/>
            <ImageDesc param={ArrayItw.image4}/>
            <ImageDesc param={ArrayItw.image5}/>
            <ImageDesc param={ArrayItw.image6}/>
            <PresPerson param={ArrayItw.pres2}/>
            <PresPerson param={ArrayItw.pres3}/>
            <div className='pagination'>
                <span>1 / 2</span>
                <Link to="/itw2">PAGE SUIVANTE ></Link>
                <div className='separator'></div>
            </div>
            { isOpen && 
                <ModalLanding/>
            }
        </StyledItw1>
    )
}
