import React from 'react'
import { Layout } from '../components/Layout'
import { Why } from '../components/Why/Why'

export const Pourquoi = () => {
    return (
        <Layout>
            <Why/>
        </Layout>
    )
}
