import React from 'react'
import { Layout } from '../components/Layout'
import { Sequence } from '../components/Sequence/Sequence'
import ArraySequence from '../Data/ArraySequence'
import { useContext } from 'react'
import { BurgerMenuContext } from '../Context/BurgerMenuContext'
import { ModalLanding } from '../components/ModalLanding/ModalLanding'

export const Sequences = () => {

    const {isOpen} = useContext(BurgerMenuContext)

    return (
        <Layout>
            {
                ArraySequence.map(seq =>
                <Sequence seq={seq} />
                
                )
            }
            { isOpen && 
                <ModalLanding/>
            }
        </Layout>
    )
}
