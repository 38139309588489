import React from 'react'
import { Chapter } from '../components/Chapter/Chapter'
import { Layout } from '../components/Layout'

export const Chapitre = () => {
    return (
        <Layout>
            <Chapter/>
        </Layout>
    )
}
